// Colors
$bg-color: var(--color-secondary);
$dot-color: var(--color-dots);

// Dimensions
$dot-size: 1px;
$dot-space: 22px;

.authPage {
  width: 100%;
  height: 100%;
  //   background: var(--color-secondary);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
        90deg,
        $bg-color ($dot-space - $dot-size),
        transparent 1%
      )
      center,
    linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
    $dot-color;
  background-size: $dot-space $dot-space;

  &:before {
    position: absolute;
    width: 500px;
    height: 706px;
    right: 10px;
    top: 66px;
    opacity: 0.5;
    content: "";
    background: rgba(64, 41, 208, 0.2);
    filter: blur(200px);
  }

  &:after {
    position: absolute;
    width: 500px;
    height: 494px;
    left: -55px;
    opacity: 0.5;
    background: rgba(135, 26, 153, 0.2);
    filter: blur(200px);
    content: "";
  }

  .loginCard {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    width: 90%;
    max-width: 400px;
    background: var(--color-secondary-transparent);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    backdrop-filter: blur(1px);
    // box-shadow: 0 10px 20px rgba(5, 10, 53, 0.276);

    h1.title {
      font-size: 1.1rem;
      margin-top: 15px;
      font-weight: 500;
    }

    form {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      margin-top: 15px;

      .disclaimer {
        padding: 10px 0;
        font-size: 0.8rem;
        opacity: 0.7;
      }
    }

    .authContent {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      margin-top: 5px;

      .disclaimer {
        padding: 10px 0;
        font-size: 0.8rem;
        opacity: 0.7;
        text-align: center;
      }
    }

    .helpText {
      padding: 10px 0;

      a {
        font-weight: 600;
        color: var(--color-accent);
      }
    }
  }
}
