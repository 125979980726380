.loader {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 15px 0 #fff, -15px 0 #fff;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #fff2;
    box-shadow: 15px 0 #fff2, -15px 0 #fff;
  }
  50% {
    background-color: #fff;
    box-shadow: 15px 0 #fff2, -15px 0 #fff2;
  }
  100% {
    background-color: #fff2;
    box-shadow: 15px 0 #fff, -15px 0 #fff2;
  }
}
