.sButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid var(--color-input-border);
  color: var(--color-font);
  margin: 5px 0;
  background: var(--color-secondary-transparent);
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;

  &.small {
    padding: 8px 10px;
    font-size: 0.8rem;
  }

  &.basic {
    background: var(--color-secondary-light);
  }

  &.aura, &.auraRounded {
    &:before {
      position: absolute;
      width: 500px;
      height: 500px;
      right: 0;
      top: 0;
      content: '';
      background: rgba(64, 41, 208, 0.2);
      filter: blur(50px);
      z-index: 0;
      transform: translate(100px, -50px);
      transition: 0.2s all ease-in-out;
    }

    &:after {
      position: absolute;
      width: 500px;
      height: 500px;
      left: 0;
      bottom: 0;
      background: rgba(135, 26, 153, 0.2);
      filter: blur(50px);
      content: '';
      z-index: 0;
      transform: translate(-100px, 50px);
      transition: 0.2s all ease-in-out;
    }
  }

  &.auraRounded {
    border-radius: 100px;
  }
  

  &:hover {
    cursor: pointer;

    &.aura, &.auraRounded {
      &:before {
        background: rgba(64, 41, 208, 0.5);
        filter: blur(50px);
        transform: translate(0, 0);
      }

      &:after {
        background: rgba(135, 26, 153, 0.5);
        filter: blur(50px);
        content: '';
        transform: translate(0, 0);
      }
    }
  }

  &:active {
    opacity: 0.5;
  }

  &.loading {
    cursor: not-allowed;

    &.aura {
      &:before {
        background: rgba(135, 26, 153, 0.2);
        filter: blur(80px);
        transform: translate(0, 0);
      }

      &:after {
        background: rgba(135, 26, 153, 0.2);
        filter: blur(80px);
        content: '';
        transform: translate(0, 0);
      }
    }
  }

  .contents {
    z-index: 1;
    font-weight: 500;
    display: flex;
    align-items: center;

    .iconSlot {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .loader {
    display: flex;
    position: absolute;
    z-index: 1;
  }
}
